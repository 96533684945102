import { Col, Typography } from "antd";
import { FC, useState } from "react";
import * as S from "./PhoneField.styles";

type PhoneVerificationProps = {
  binder: any;
  showVerification: boolean;
};

const { Text } = Typography;

const PhoneVerification: FC<PhoneVerificationProps> = ({
  showVerification,
  binder,
}) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [error, setError] = useState("");
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(true);
  const [verificationCodeVerified, setVerificationCodeVerified] =
    useState(false);

  /**
   * Called when the user is entering the verification code.
   */
  const handleVerificationCodeChange = (event: any) => {
    const { value } = event.target;

    if (value.replace(/\D/g, "").length === 8) {
      setVerifyButtonDisabled(false);
    } else {
      setVerifyButtonDisabled(true);
    }

    setVerificationCode(value);
  };

  /**
   * Use the binder to send the verification code to the user
   */
  const handleSendVerificationCode = async () => {
    try {
      await binder.sendVerificationCode();
      setVerificationCodeSent(true);
      setError("");
    } catch (e) {
      // TODO: Handle error that should be produced by the binder when doing the request to the API
      setError("Failed to send verification code");
    }
  };

  /**
   * Use the binder to verify the code entered by the user
   */
  const handleVerifyCode = async () => {
    const verified = await binder.verifyCode(verificationCode);
    if (verified) {
      setVerificationCodeVerified(true);
      setError("");
    } else {
      setError("Código inválido");
    }
  };

  return (
    <>
      {verificationCodeSent ? (
        <>
          <Col sm={8} xs={24}>
            <S.VerificationField
              mask="9 9 9 9 9 9 9 9"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
            />
            {error && <Text type="danger">{error}</Text>}
            {!verificationCodeVerified && (
              <div>Puede tardar hasta un minuto en llegar...</div>
            )}
            {verificationCodeVerified && (
              <div>Número de teléfono verificado con éxito. PRESIONA GUARDAR PARA COMPLETAR TU REGISTRO.</div>
            )}
          </Col>
          <Col sm={4} xs={24}>
            <S.VerificationButton
              type="primary"
              onClick={handleVerifyCode}
              disabled={verifyButtonDisabled}
            >
              Verificar
            </S.VerificationButton>
          </Col>
        </>
      ) : (
        <Col sm={8} xs={24}>
          <S.VerificationButton
            type="primary"
            disabled={!showVerification}
            onClick={handleSendVerificationCode}
          >
            Enviar usuario y NIP
          </S.VerificationButton>
        </Col>
      )}
    </>
  );
};

export default PhoneVerification;
