import { FC, useState } from "react";
import * as S from "./FormInfo.styles";
import { Button, Drawer } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { RootGizmo } from "flowy-3-core";

type FormInfoProps = {
  gizmo: RootGizmo;
};

const FormInfo: FC<FormInfoProps> = ({ gizmo }) => {
  const [open, setOpen] = useState(false);
  const username: string | undefined = gizmo.getData()?.username;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <S.FormInfo>
      <Button
        onClick={showDrawer}
        shape="circle"
        icon={<InfoCircleOutlined />}
      />
      <Drawer
        title="Info Registro"
        placement="right"
        onClose={onClose}
        open={open}
      >
        {username && (
          <p>
            <b>Usuario que creó: </b>
            {username}
          </p>
        )}
      </Drawer>
    </S.FormInfo>
  );
};

export default FormInfo;
