import { ReactNode } from "react";
import { IError, IGizmoFeatures } from "flowy-3-core";
import TextHelp from "./TextHelp";
import Errors from "./Errors/Errors";
import { Form } from "antd";
import GizmoLabel from "./GizmoLabel";

type GizmoDisplayProps = {
  features: IGizmoFeatures;
  errors: IError[];
  children: ReactNode[] | ReactNode;
};

const { Item } = Form

const GizmoWrapper = ({ features, errors, children }: GizmoDisplayProps) => {
  return (
    <>
      {features.display === true && (
        <Item
          label={<GizmoLabel {...features} />}
          required={features.required}
          labelCol={{ span: 24 }}
        >
          {children}
          {features.help?.type === "text" && <TextHelp features={features} />}
          <Errors errors={errors} />
        </Item>
      )}
    </>
  );
};

export default GizmoWrapper;
