import { FC, useEffect, useState } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import { GizmoWrapper } from "../../utils";
import * as S from "./BooleanField.styles";

type BooleanFieldProps = {
  gizmo: Gizmo;
};

const BooleanField: FC<BooleanFieldProps> = ({ gizmo }) => {
  const { features, binder, errors } = useGizmo({ gizmo });
  const [value, setValue] = useState<boolean | undefined>();

  useEffect(() => {
    if (binder) {
      const initValue = binder.getValue();
      if (initValue !== undefined) {
        setValue(initValue);
      }

      binder.value.subscribe((v: boolean) => {
        setValue(v);
      });
    }
  }, [binder]);

  const handleChange = async (event: any) => {
    const { value } = event.target;
    await binder?.setValue(value);
  };

  const commonProps = {
    onChange: handleChange,
    disabled: !features.editable
  }

  return (
    <GizmoWrapper features={features} errors={errors}>
      {!features.editable ? (
        value !== undefined && <span>{value ? 'Sí' : 'No'}</span>
      ) : (
        <>
          <S.BooleanField
            value={true}
            checked={value === true}
            {...commonProps}
          >
            Sí
          </S.BooleanField>
          <S.BooleanField
            value={false}
            checked={value === false}
            {...commonProps}
          >
            No
          </S.BooleanField></>
      )}
    </GizmoWrapper>
  );
};

export default BooleanField;
