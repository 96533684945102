import { Upload } from "antd";
import RWebcam from "react-webcam";
import styled from "styled-components";

const MultimediaField = styled(Upload)`
  width: 100%;
`;

const Container = styled.div`
  border: 1px dotted #222;
  padding: 10px;
  border-radius: 15px;
`

const WebCam = styled(RWebcam)`
  max-width: 100%;
`;

const WebCamContainer = styled.div`
  text-align: center;
  background-color: "#eee";
  padding: 10px;
  border-radius: 15px;
`;

const CenterContainer = styled.div`
  text-align: center;
`;


export {
  MultimediaField,
  WebCam,
  Container,
  WebCamContainer,
  CenterContainer
};
