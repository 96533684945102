import { FC } from "react";
import {
  IFlowyProject,
  IFlowyRoute,
  IPlatformCallbacks,
  useFlowyProject,
} from "flowy-3-core";
import { Routes, Route } from "react-router-dom";
import FlowyFoundation from "../FlowyFoundation/FlowyFoundation";
import FlowyFoundationRoute from "./FlowyFoundationRoute";
import { RedirectToRoot, PrivateRoutes } from "../../../../client/routes";
import Navbar from "./Navbar";
import useAuth from "../../../../client/useAuth";
import * as S from "./FlowyProject.styles";

type FlowyProjectProps = {
  config: IFlowyProject;
  platformCallbacks?: IPlatformCallbacks;
  params: any;
};

const FlowyProject: FC<FlowyProjectProps> = ({
  params,
  config,
  platformCallbacks,
}) => {
  // const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { flowyRoutes } = useFlowyProject({ config });

  // console.log("-----------------------flowyRoutes: ", flowyRoutes);
  // console.log('FlowyProject->location: ', location);

  return (
    <S.FlowyProject>
      {isAuthenticated && config.navbar && <Navbar config={config} />}

      {flowyRoutes.length > 0 && (
        <Routes>
          {flowyRoutes.map((fr: IFlowyRoute) => {
            if (
              (fr.path === "/login" || fr.path === "/sign_up") &&
              fr.configs &&
              fr.configs.length > 0
            ) {
              return (
                <Route key={fr.path} element={<RedirectToRoot />}>
                  <Route
                    key={fr.path}
                    path={fr.path}
                    element={
                      <FlowyFoundationRoute
                        configs={fr.configs}
                        platformCallbacks={platformCallbacks}
                        params={params}
                      />
                    }
                  />
                </Route>
              );
            } else if (fr.configs && fr.configs.length > 0) {
              return (
                <Route element={<PrivateRoutes />} key={fr.path}>
                  <Route
                    key={fr.path}
                    path={fr.path}
                    element={
                      <FlowyFoundationRoute
                        configs={fr.configs}
                        platformCallbacks={platformCallbacks}
                        params={params}
                      />
                    }
                  />
                </Route>
              );
            } else if (fr.configs && fr.configs.length === 0) {
              return (
                <Route
                  key={fr.path}
                  path={fr.path}
                  element={
                    <FlowyFoundation
                      platformCallbacks={platformCallbacks}
                      params={params}
                    />
                  }
                />
              );
            } else {
              return <div key={fr.path}>Empty</div>;
            }
          })}
        </Routes>
      )}
    </S.FlowyProject>
  );
};

export default FlowyProject;
