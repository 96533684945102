import styled from "styled-components";

const FlowyProject = styled.div`
  height: 100%;
  width: 100%;
`;

const Navbar = styled.div``;

export { FlowyProject, Navbar };
