import { Gizmo, ILocationData, useGizmo } from "flowy-3-core";
import { FC, useState, useEffect } from "react";
import {
  Button,
  Divider,
  Input,
  Modal,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { GizmoWrapper } from "../../utils";
import GoogleLocation from "./GoogleLocation";
import OPSLocation from "./OPSLocation";

type LocationFieldProps = {
  gizmo: Gizmo;
};

export type LocationMapProps = {
  currentLocation: google.maps.LatLngLiteral | null;
  setCurrentLocation: (loc: google.maps.LatLngLiteral | null) => void;
  selectedLocation: google.maps.LatLngLiteral | null;
  setSelectedLocation: (loc: google.maps.LatLngLiteral | null) => void;
};

const { Text } = Typography;

const mapOptions = [
  { value: "google", label: "Google Maps" },
  {
    value: "ops",
    label: "Open Street Maps",
  },
];

const LocationField: FC<LocationFieldProps> = ({ gizmo }) => {
  const { features, binder, errors, config } = useGizmo({ gizmo });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mapOption, setMapOption] = useState("ops");

  const [currentLocation, setCurrentLocation] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [selectedLocation, setSelectedLocation] =
    useState<google.maps.LatLngLiteral | null>(null);

  useEffect(() => {
    if (binder) {
      const locations: ILocationData[] = binder.getLocations();

      if (locations.length === 1) {
        setSelectedLocation({
          lat: locations[0].latitude,
          lng: locations[0].longitude,
        });
      }

      binder.locations.subscribe((locs: ILocationData[]) => {
        setSelectedLocation({
          lat: locs[0].latitude,
          lng: locs[0].longitude,
        });
      });
    }
  }, []);

  const onChangeDescription = (e: any) => {
    console.log("EEEEE", e.target.value);
  };

  const handleSetSelectedLocation = async (
    loc: google.maps.LatLngLiteral | null
  ) => {
    await binder?.setLocation({
      latitude: loc?.lat,
      longitude: loc?.lng,
    });
  };

  const renderMap = () => {
    if (mapOption === "google") {
      return (
        <GoogleLocation
          currentLocation={currentLocation}
          selectedLocation={selectedLocation}
          setCurrentLocation={setCurrentLocation}
          setSelectedLocation={handleSetSelectedLocation}
        />
      );
    } else if (mapOption === "ops") {
      return (
        <OPSLocation
          currentLocation={currentLocation}
          selectedLocation={selectedLocation}
          setCurrentLocation={setCurrentLocation}
          setSelectedLocation={handleSetSelectedLocation}
        />
      );
    }
  };

  const onChangeMapOption = (opt: string) => {
    setMapOption(opt);
  };

  const closeLocationModal = () => {
    setIsModalOpen(false);
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      {!selectedLocation && (
        <Button onClick={() => setIsModalOpen(true)}>Agregar ubicación</Button>
      )}
      {selectedLocation && (
        <div>
          <Text>
            {selectedLocation?.lat}, {selectedLocation?.lng}
            {features.editable && (
              <>
                <Divider type="vertical" />
                {/* <Tooltip title="Editar">
                  <EditTwoTone onClick={() => setIsModalOpen(true)} />
                </Tooltip> */}
                <Divider type="vertical" />
                {/* <Tooltip title="Eliminar">
                  <DeleteTwoTone
                    onClick={() => setSelectedLocation(null)}
                    twoToneColor="#F00"
                  />
                </Tooltip> */}
              </>
            )}
          </Text>
        </div>
      )}
      {/* <Input placeholder="Descripción" onChange={onChangeDescription} /> */}
      <Modal
        title="Agregar ubicación"
        open={isModalOpen}
        width={"90%"}
        onCancel={closeLocationModal}
        footer={
          <Button onClick={closeLocationModal} type="primary">
            Aceptar
          </Button>
        }
      >
        <Select
          defaultValue="ops"
          options={mapOptions}
          onChange={onChangeMapOption}
          style={{ width: 200 }}
        />
        {renderMap()}
      </Modal>
    </GizmoWrapper>
  );
};
export default LocationField;
