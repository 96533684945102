import emptyOne from "./empty/1.svg";
import emptyTwo from "./empty/2.svg";
import emptyThree from "./empty/3.svg";
import emptyFour from "./empty/4.svg";
import emptyFive from "./empty/5.svg";
import fullOne from "./full/1.svg";
import fullTwo from "./full/2.svg";
import fullThree from "./full/3.svg";
import fullFour from "./full/4.svg";
import fullFive from "./full/5.svg";

const empty = [emptyOne, emptyTwo, emptyThree, emptyFour, emptyFive];
const full = [fullOne, fullTwo, fullThree, fullFour, fullFive];

export { empty, full };
