import * as React from "react";
import usePlatformCB from "./usePlatformCB";
import { FlowyFoundation } from "../components/gizmos";

const PublicRootGizmo: React.FC = () => {
  const { platformOperationCB, params } = usePlatformCB();

  console.log("************************params: ", params);

  return (
    <div>
      <h3>Public Root Gizmo</h3>
      <FlowyFoundation
        params={params}
        platformCallbacks={{ platformOperationCB }}
      />
    </div>
  );
};

export default PublicRootGizmo;
