import { fileToBase64 } from "../../utils/functions";
import { pdfDownload } from "../../../../assets/images";

/**
 * Transform a file so it can be consumed by the Upload antd component
 *
 * @param {File} file file to transform
 * @returns
 */
function transformFile(file: File): Promise<any> {
  return new Promise<any>(async (resolve) => {
    const transformedFile: any = { ...file };
    transformedFile.name = file.name;

    if (file.type === "image/jpeg") {
      transformedFile.thumbUrl =
        "data:image/jpeg;base64," + (await fileToBase64(file));
    } else if (file.type === "image/png") {
      transformedFile.thumbUrl =
        "data:image/png;base64," + (await fileToBase64(file));
    } else if (file.type === "application/pdf") {
      transformedFile.url = pdfDownload;
    }

    resolve(transformedFile);
  });
}

/**
 * Transform a list of file so they can be consumed by the Upload antd component
 *
 * @param {File[]} files files to transform
 * @returns
 */
function transformFiles(files: File[]): Promise<any[]> {
  return new Promise<any[]>(async (resolve) => {
    const transformedFiles: any[] = [];

    for (const file of files) {
      transformedFiles.push(await transformFile(file));
    }

    resolve(transformedFiles);
  });
}

export default transformFiles;
