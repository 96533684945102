import { FC, useEffect, useState } from "react";
import { IGizmoFeatures } from "flowy-3-core";

type TextHelpProps = {
  features: IGizmoFeatures;
};

/**
 * Render help when configured as text
 *
 * @param {IGizmoFeatures} features gizmo features arriving form the core
 * @returns
 */
const TextHelp: FC<TextHelpProps> = ({ features }) => {
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    let message = features.help?.message || "";
    if (features.help?.message.includes("<a ")) {
      // If the message contains an <a> tag, we need to add target="_blank" attribute to it

      // Create a new DOMParser object
      const parser = new DOMParser();
      // Parse the string into a DOM object
      const doc = parser.parseFromString(features.help.message, "text/html");
      // Find the <a> tag
      const link = doc.querySelector("a");

      if (link) {
        // Add target="_blank" attribute to the <a> tag
        link.setAttribute("target", "_blank");

        // Convert the modified DOM object back into a string
        const modifiedStr = doc.documentElement.outerHTML;

        message = modifiedStr;
      }
    }
    setMessage(message);
  }, [features]);

  return (
    <>{message && <span dangerouslySetInnerHTML={{ __html: message }} />}</>
  );
};

export default TextHelp;
