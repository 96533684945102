import { FC, useState, useEffect } from "react";
import { IGizmo, IPlatformCallbacks } from "flowy-3-core";
import FlowyFoundation from "../FlowyFoundation/FlowyFoundation";

type FlowyFoundationRouteProps = {
  configs: IGizmo[];
  platformCallbacks?: IPlatformCallbacks;
  params: any;
};

/**
 * This is a wrapper on the FlowyFoundation component, it's reason of existence is in order to
 * re-render the FlowyFoundation. This is done because react-router doesn't re-render it.
 *
 * @param {IGizmo[]} configs list of Gizmo configurations
 * @returns
 */
const FlowyFoundationRoute: FC<FlowyFoundationRouteProps> = ({
  configs,
  platformCallbacks,
  params,
}) => {
  // TODO: change this from managing one Gizmo config to manage multiple, this in order
  // TODO: to handle annex forms
  const [currentConfig, setCurrentConfig] = useState<IGizmo | undefined>(
    undefined
  );

  useEffect(() => {
    if (configs && configs.length > 0) {
      setCurrentConfig(undefined);
    }
  }, [configs]);

  useEffect(() => {
    if (configs && configs.length > 0 && currentConfig === undefined) {
      setCurrentConfig(configs[0]);
    }
  }, [currentConfig, configs]);

  return (
    <>
      {currentConfig && (
        <FlowyFoundation
          config={currentConfig}
          platformCallbacks={platformCallbacks}
          params={params}
        />
      )}
    </>
  );
};

export default FlowyFoundationRoute;
