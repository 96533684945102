import { FC, useState, useEffect } from "react";
import { Gizmo, RootGizmo, useGizmo } from "flowy-3-core";
import * as S from "./Section.styles";
import renderGizmos from "../../render-gizmos";

type SectionProps = {
  gizmo: Gizmo;
};

const Section: FC<SectionProps> = ({ gizmo }) => {
  const [active, setActive] = useState<boolean>(false);
  const { config, features } = useGizmo({ gizmo });
  let childGizmos: (Gizmo | RootGizmo)[] = gizmo.getGizmos() || [];


  useEffect(() => {
    if (features.section?.active !== undefined) {
      setActive(features.section?.active);
    }
  }, [features.section]);

  return (
    <>
      {active && (
        <S.Section id={`section-${gizmo.getConfig().fid}`}>
          {/* <h1>Section: {features.label}</h1> */}
          {childGizmos.length > 0 && renderGizmos(childGizmos)}
          {childGizmos.length === 0 && (
            <h1 id={`section-${config.uid}-empty`}>Sección vacía</h1>
          )}
        </S.Section>
      )}
    </>
  );
};

export default Section;
