import { FC, useEffect } from "react";
import {
  Circle,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { LocationMapProps } from "./LocationField";

const GoogleLocation: FC<LocationMapProps> = (props) => {
  const {
    currentLocation,
    selectedLocation,
    setCurrentLocation,
    setSelectedLocation,
  } = props;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "...",
    libraries: ["geometry"],
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setCurrentLocation({ lat: latitude, lng: longitude });
      setSelectedLocation(
        selectedLocation || { lat: latitude, lng: longitude }
      );
    });
  }, []);

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    const latLng = e.latLng;
    if (latLng) {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(currentLocation!.lat, currentLocation!.lng),
        latLng
      );

      if (distance <= 50) {
        setSelectedLocation({ lat: latLng.lat(), lng: latLng.lng() });
      }
    }
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "65vh" }}
      center={currentLocation || undefined}
      zoom={18}
    >
      {currentLocation && (
        <Circle
          center={currentLocation}
          radius={50}
          onClick={handleMapClick}
          options={{
            strokeColor: "#00AA00",
            fillColor: "#00AA00",
          }}
        />
      )}
      {selectedLocation && <Marker position={selectedLocation} />}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default GoogleLocation;
