import { latLng } from "leaflet";
import { FC, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  Circle,
} from "react-leaflet";
import { LocationMapProps } from "./LocationField";

const OPSLocation: FC<LocationMapProps> = (props) => {
  const {
    currentLocation,
    selectedLocation,
    setCurrentLocation,
    setSelectedLocation,
  } = props;

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setCurrentLocation({ lat: latitude, lng: longitude });
      setSelectedLocation(
        selectedLocation || { lat: latitude, lng: longitude }
      );
    });
  }, []);

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        if (e.latlng && currentLocation) {
          const { lat, lng } = e.latlng;
          const distance = latLng(
            currentLocation.lat,
            currentLocation.lng
          ).distanceTo(latLng(lat, lng));
          if (distance <= 50) {
            setSelectedLocation({ lat: e.latlng.lat, lng: e.latlng.lng });
          }
        }
      },
    });

    return selectedLocation === null ? null : (
      <Marker position={selectedLocation} />
    );
  };

  return currentLocation ? (
    <MapContainer
      center={currentLocation}
      zoom={18}
      style={{ width: "100%", height: "65vh" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {selectedLocation && <LocationMarker />}
      {currentLocation && (
        <Circle
          center={currentLocation}
          radius={50}
          pathOptions={{ color: "#00AA00", fillColor: "#00AA00" }}
        />
      )}
    </MapContainer>
  ) : (
    <></>
  );
};

export default OPSLocation;
