import { FC, useState, useEffect } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./NumberField.styles";
import { GizmoWrapper } from "../../utils";

type NumberFieldProps = {
  gizmo: Gizmo;
};

const NumberField: FC<NumberFieldProps> = ({ gizmo }) => {
  const { features, binder, config, errors } = useGizmo({ gizmo });
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (binder) {
      binder.value.subscribe((v: any) => {
        setValue(v);
      });
      const initValue = binder.getValue();
      if (initValue !== undefined) {
        setValue(initValue);
      }
    }
  }, [binder]);

  const handleChange = (v: any) => {
    binder?.setValue(v);
  };

  const handlePressEnter = async () => {
    await binder?.sendSpecialKey("enter");
  };

  let notEditableValue = "";
  if (!features.editable) {
    if (config.ops?.numberField?.prefix === "$") {
      // Right now we're only supporting $ prefix
      notEditableValue = `$ `;
    }
    notEditableValue += binder?.getMaskedValue();
  }

  return (
    <GizmoWrapper features={features} errors={errors}>
      {!features.editable ? (
        <span>{notEditableValue}</span>
      ) : (
        <S.NumberField
          id={`number_field-${config.fid}`}
          aria-label={`input-${config.fid}`}
          formatter={() => {
            let value = binder.getMaskedValue();
            if (config.ops?.numberField?.prefix === "$") {
              // Right now we're only supporting $ prefix
              value = `$ ${value}`;
            }

            return value;
          }}
          parser={(value) => {
            return value!.replace(/\$\s?|(,*)/g, "");
          }}
          value={value}
          onChange={handleChange}
          onPressEnter={handlePressEnter}
        />
      )}
    </GizmoWrapper>
  );
};

export default NumberField;
