import { Row } from "antd";
import { SCREEN_SIZES } from "flowy-3-core";
import styled from "styled-components";

const screenMaxWidths = {
  small: `(max-width: ${SCREEN_SIZES.small})`,
  medium: `(max-width: ${SCREEN_SIZES.medium})`,
  large: `(max-width: ${SCREEN_SIZES.large})`,
  extraLarge: `(min-width: ${SCREEN_SIZES.extraLarge})`,
};

const FormInfo = styled(Row)`
  position: fixed;
  @media ${screenMaxWidths.extraLarge} {
    left: calc(100vw - 10%);
  }
  @media ${screenMaxWidths.large} {
    left: calc(100vw - 20%);
  }
  @media ${screenMaxWidths.medium} {
    left: calc(100vw - 30%);
  }
  @media ${screenMaxWidths.small} {
    left: calc(100vw - 45%);
  }
`;

export { FormInfo };
