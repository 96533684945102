import { useState, useEffect } from "react";
import authentication from "./authentication";

/**
 * Custom hook which exposes a variable to know if a user is logged in
 */
const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    authentication.isAuthenticated().then((isAuth: boolean) => {
      setIsAuthenticated(isAuth);
    });
  });

  return {
    isAuthenticated,
  };
};

export default useAuth;
