import { Gizmo, IIndexColumn } from "flowy-3-core";

interface IParams {
  operation: string;
  gizmo: Gizmo;
  row: any;
  column: IIndexColumn;
}

/**
 * Download pdf version of the current RootGizmoData
 * @param {Gizmo} gizmo index gizmo
 * @param {any} row row from which we're going to download the pdf
 */
async function downloadPdf({ operation, gizmo, column, row }: IParams) {
  const binder = gizmo.getBinder();
  const rgfid = gizmo.getParams().fid;
  const id = row.id;

  const { data } = await binder.cellInteraction({
    column,
    row,
    operation,
    params: {
      rgfid,
      id,
    },
  });

  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "export.pdf");
  document.body.appendChild(link);
  link.click();
}

export default downloadPdf;
