import { FC } from "react";
import { IFlowyProject } from "flowy-3-core";
import FlowyFoundation from "../FlowyFoundation/FlowyFoundation";
import {
  quintanaRooHeader,
  quintanaRooSeparator,
} from "../../../../assets/images";
import * as S from "./FlowyProject.styles";

type NavbarProps = {
  config: IFlowyProject;
};

const Navbar: FC<NavbarProps> = ({ config }) => {
  return (
    <S.Navbar>
      <img
        src={quintanaRooHeader}
        alt="Navbar Image"
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      ></img>
      <img
        src={quintanaRooSeparator}
        alt="Navbar Separator"
        style={{
          maxWidth: "100%",
        }}
      ></img>
      {config.navbar?.gizmos &&
        Array.isArray(config.navbar.gizmos) &&
        config.navbar.gizmos.length === 1 && (
          <FlowyFoundation config={config.navbar.gizmos[0]} />
          // <div>Gizmos {config.navbar.gizmos[0].fid}</div>
        )}
    </S.Navbar>
  );
};

export default Navbar;
