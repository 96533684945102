import styled from "styled-components";
import { TScreenColumns, SCREEN_SIZES } from "flowy-3-core";

const screenMaxWidths = {
  small: `(max-width: ${SCREEN_SIZES.small})`,
  medium: `(max-width: ${SCREEN_SIZES.medium})`,
  large: `(max-width: ${SCREEN_SIZES.large})`,
  extraLarge: `(min-width: ${SCREEN_SIZES.extraLarge})`,
};

export interface IContainerResize {
  columns: TScreenColumns;
}

/**
 * Gizmo's container components will inherit from this so they have the resize
 * functionality
 */
const GizmoContainerResize = styled.div<IContainerResize>`
  @media ${screenMaxWidths.large} {
    width: ${(props) => 100 / props.columns.l}%;
  }
  @media ${screenMaxWidths.medium} {
    width: ${(props) => 100 / props.columns.m}%;
  }
  @media ${screenMaxWidths.small} {
    width: ${(props) => 100 / props.columns.s}%;
  }
  @media ${screenMaxWidths.extraLarge} {
    width: ${(props) => 100 / props.columns.xl}%;
  }
`;

export default GizmoContainerResize;
