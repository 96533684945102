import { CameraOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Divider, Image } from "antd";
import { FC, useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { getFileFromBase64 } from "../../utils/functions/getFile";
import transformFiles from "../FileField/transformFiles";
import * as S from "./IdOcrField.styles";

export type Capture = {
  side: 'front' | 'back' | undefined,
  setFileList: ((list: any[]) => void)
}

type ImageCaptureProps = {
  binder: any,
  capture: Capture,
  captureActive: boolean,
  setCaptureActive: (active: boolean) => void
};

const ImageCapture: FC<ImageCaptureProps> = (props) => {

  const { binder, capture, captureActive, setCaptureActive } = props
  const [img, setImg] = useState<string | null>();
  const [loading, setLoading] = useState(false)
  const webcamRef = useRef<Webcam>(null);

  const handleCapture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setImg(imageSrc);
  }, [webcamRef]);

  const addCapturedImg = async () => {
    if (img) {
      setLoading(true)
      const filename = `image-capture-camera-${(new Date()).getTime()}.jpeg`;
      const file = await getFileFromBase64(img, filename, "image/jpeg");
      await binder.setFile(capture.side, file);
      capture.setFileList(await transformFiles([file]));
      setCaptureActive(false)
      setLoading(false)
    }
  };

  return <>
    {captureActive && (
      <S.WebCamContainer>
        {img ? (
          <div>
            <Image src={img} alt="screenshot" />
            <div style={{ marginTop: 5 }}>
              <Button onClick={() => setImg(null)} disabled={loading}>
                Tomar de nuevo
              </Button>
              <Divider type="vertical" />
              <Button onClick={addCapturedImg} disabled={loading} loading={loading}>
                Aceptar
              </Button>
              <Divider type="vertical" />
              <Button
                icon={<CloseOutlined />}
                danger
                onClick={() => setCaptureActive(false)}
                disabled={loading}
              />
            </div>
          </div>
        ) : (
          <div>
            <S.WebCam
              audio={false}
              mirrored={true}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
            <div>
              <Button onClick={handleCapture}>Tomar foto</Button>
              <Divider type="vertical" />
              <Button
                icon={<CloseOutlined />}
                danger
                onClick={() => setCaptureActive(false)}
              />
            </div>
          </div>
        )}
      </S.WebCamContainer>
    )}
  </>
}

export default ImageCapture