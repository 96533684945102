import styled from "styled-components";
import { GizmoContainerResize } from "../../utils";
import { TScreenColumns } from "flowy-3-core";

const Text = styled(GizmoContainerResize)`
  // border: 2px dashed green;
`;

export interface IContainerResize {
  columns: TScreenColumns;
}

const TextDiv = styled.div<IContainerResize>`;

// ! Adding more props to the Text component
// interface IText {
//   dude: string;
// }

// const Text = styled(ColumnResize)<IText>`
//   border: 2px dashed green;
//   x: ${props => props.dude}
// `;

export { Text, TextDiv };
