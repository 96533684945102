import { FC } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./Text.styles";
import { GizmoDisplay } from "../../utils";

type TextProps = {
  gizmo: Gizmo;
};

const Text: FC<TextProps> = ({ gizmo }) => {
  const { features } = useGizmo({ gizmo });

  return (
    <GizmoDisplay features={features}>
      <S.TextDiv id={`text-${gizmo.getConfig().fid}`} columns={gizmo.getColumns()}>
        <h4>{features.label}</h4>
      </S.TextDiv>
    </GizmoDisplay>
  );
};

export default Text;
