export const translations = {
  "action-alt-camera": "Cámara del dispositivo",
  "action-alt-gallery": "De la galería",
  "action-message": "Escanear",
  "action-message-camera": "Cámara del dispositivo",
  "action-message-camera-disabled": "Cámara inhabilitada",
  "action-message-camera-not-allowed": "Cámara no permitida",
  "action-message-camera-in-use": "Cámara en uso",
  "action-message-image": "De la galería",
  "action-message-image-not-supported": "Imagen no soportada",
  "camera-disabled": "Cámara inhabilitada",
  "camera-not-allowed": "No se puede acceder a la cámara",
  "camera-in-use":
    "La cámara ya está siendo utilizada por otra aplicación",
  "camera-generic-error": "No se puede acceder a la cámara",
  "camera-feedback-scan-front": "Escanea el anverso del documento",
  "camera-feedback-scan-back": "Escanea el reverso del documento",
  "camera-feedback-flip": "Dar la vuelta al reverso",
  "camera-feedback-barcode-message": "Escanea el código de barras",
  "camera-feedback-move-farther": "Muévete más lejos",
  "camera-feedback-move-closer": "Muévete mas cerca",
  "camera-feedback-adjust-angle": "Ajustar el ángulo",
  "camera-feedback-barcode":
    "Acercar el código de barras y mantenerlo centrado",
  "drop-info": "Suelta la imagen aquí",
  "drop-error":
    "Vaya, no admitimos ese formato de imagen. Cargue un archivo JPEG o PNG.",
  "initialization-error":
    "No se pudo cargar el componente. Intente usar otro dispositivo o actualice su navegador.",
  "process-image-box-first": "Imagen del frente",
  "process-image-box-second": "Imagen del reverso",
  "process-image-box-add": "Añadir imagen",
  "process-image-upload-cta": "Subir",
  "process-image-message": "Sólo un momento",
  "process-image-message-inline": "Procesando",
  "process-image-message-inline-done": "Procesamiento hecho",
  "process-api-message": "Sólo un momento",
  "process-api-retry": "Procesar de nuevo",
  "feedback-scan-unsuccessful-title": "Escaneo fallido",
  "feedback-scan-unsuccessful":
    "No pudimos reconocer su documento. Inténtalo de nuevo.",
  "feedback-error-generic":
    "Vaya, eso no funcionó. Por favor, dale otra oportunidad.",
  "check-internet-connection": "Compruebe la conexión a Internet.",
  "network-error": "Error de red.",
  "scanning-not-available": "Escaneo no disponible.",
  "modal-window-close": "Cerrar",
};