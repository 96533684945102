import { FC, useState, useEffect } from "react";
import { Gizmo, useGizmo, IGizmoCatalogOptionV2 } from "flowy-3-core";
import { GizmoWrapper } from "../../utils";
import * as S from "./SelectField.styles";
import { Checkbox, Input } from "antd";

type SelectFieldProps = {
  gizmo: Gizmo;
};

const SelectField: FC<SelectFieldProps> = ({ gizmo }) => {
  const { features, config, binder, errors } = useGizmo({ gizmo });
  const [options, setOptions] = useState<IGizmoCatalogOptionV2[] | undefined>();
  const [selected, setSelected] = useState<any[]>([]);
  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    if (binder) {
      const initOptions: IGizmoCatalogOptionV2[] = binder.getOptions();
      setOptions(initOptions);

      binder.options.subscribe((options: IGizmoCatalogOptionV2[]) => {
        setOptions(options);
      });
      binder.selectedOptions.subscribe((selected: IGizmoCatalogOptionV2[]) => {
        setSelected(selected);
      });
      binder.otherValue.subscribe((otherValue: string) => {
        setOtherValue(otherValue);
      });

      const initSelected: IGizmoCatalogOptionV2[] = binder.getSelected();
      if (initSelected.length > 0) {
        setSelected(initSelected);

        const initOther = binder.getOtherValue();
        if (initOther) {
          setOtherValue(initOther);
        }
      }
    }
  }, [binder, config]);

  const handleSelectChange = async (value: any) => {
    value = config?.ops?.selectField?.isMultiple ? value : [value];
    let option = options?.filter((option) => value.includes(option.value));
    if (option && binder) {
      if (
        config.ops?.selectField?.controlType === "radio" &&
        selected.length &&
        option[0].value === selected[0].value
      )
        option = [];

      await binder.setSelected(option);
    }
  };

  const handleTristateChange = async (option: any) => {
    const currVal = selected?.find((opt) => opt.value === option.value);

    let newSelected = selected;
    if (!currVal) {
      newSelected.push({ ...option, checked: true });
    } else if (currVal.checked) {
      newSelected = newSelected.map((opt) =>
        option.value === opt.value ? { ...opt, checked: false } : opt
      );
    } else {
      newSelected = newSelected.filter((opt) => option.value !== opt.value);
    }
    if (binder) {
      await binder.setSelected(newSelected);
    }
  };

  /**
   * If the user selects other a new input will appear to write a custom value.
   *
   * @param event event with the user input value
   */
  const handleInputChange = async (event: any) => {
    if (binder) await binder.setOtherValue(event.target?.value);
  };

  const handleClear = async () => {
    await binder.setSelected([]);
  };

  const getComponent = () => {
    if (features.editable === false) {
      return (
        <>
          {selected.map((option, i) => (
            <span className="notranslate" key={option.f_id}>
              {option.value}
              {option.f_id === "o0" && `: ${binder.getOtherValue()}`}
            </span>
          ))}
          <br />
        </>
      );
    } else if (config.ops?.selectField?.controlType === "radio") {
      return (
        <>
          {options?.map((option, i) => (
            <Checkbox
              key={option.f_id}
              value={option.value}
              checked={selected.some((e: any) => e.value === option.value)}
              onChange={() => handleSelectChange(option.value)}
            >
              {option.value}
            </Checkbox>
          ))}
        </>
      );
    } else if (config.ops?.selectField?.multipleTristate) {
      return options?.map((option, i) => {
        const isChecked = selected.some(
          (sel: any) => sel.value === option.value && sel.checked === true
        );
        const isIndeterminate = !selected.some(
          (sel: any) => sel.value === option.value
        );
        return (
          <div key={option.f_id}>
            <S.SelectTristate
              key={option.f_id}
              value={option.value}
              onChange={() => handleTristateChange(option)}
              checked={isChecked}
              indeterminate={isIndeterminate}
            >
              {option.value}
            </S.SelectTristate>
          </div>
        );
      });
    } else {
      return (
        <>
          <S.SelectField
            showSearch={config.ops?.selectField?.search}
            id={`select_field-${config.fid}`}
            onChange={handleSelectChange}
            mode={config?.ops?.selectField?.isMultiple ? "multiple" : undefined}
            allowClear
            onClear={handleClear}
            value={selected}
            disabled={!options?.length}
          >
            {options?.map((option, i) => (
              <S.SelectField.Option key={option.f_id} value={option.value}>
                {option.value}
              </S.SelectField.Option>
            ))}
          </S.SelectField>

          {config.ops?.selectField?.other && selected[0]?.f_id === "o0" && (
            <Input
              style={{ marginTop: 10 }}
              onChange={handleInputChange}
              value={otherValue}
            />
          )}
        </>
      );
    }
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      {getComponent()}
    </GizmoWrapper>
  );
};

export default SelectField;
