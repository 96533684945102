import { FC, useState, useEffect } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import { VictoryChart, VictoryBar, VictoryArea, VictoryLine } from "victory";
import * as S from "./Chart.styles";

type ChartProps = {
  gizmo: Gizmo;
};

const Chart: FC<ChartProps> = ({ gizmo }) => {
  const { features, binder } = useGizmo({ gizmo });
  const [data, setData] = useState<any[] | undefined>();
  const [chartType, setChartType] = useState<string>("bar");

  useEffect(() => {
    if (binder) {
      let type = "bar";
      if (binder.getConfig().ops?.chart?.type) {
        type = binder.getConfig().ops?.chart?.type;
      }

      setChartType(type);
      setData(binder.getData());
    }
  }, [binder]);

  return (
    <S.Chart id={`chart-${gizmo.getConfig().fid}`} columns={gizmo.getColumns()}>
      <label>{features.label}</label>
      {data && (
        <VictoryChart domainPadding={20}>
          {chartType === "bar" && <VictoryBar data={data} />}
          {chartType === "area" && <VictoryArea data={data} />}
          {chartType === "line" && <VictoryLine data={data} />}
        </VictoryChart>
      )}
    </S.Chart>
  );
};

export default Chart;
