import { FC, useEffect, useState } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./RateField.styles";
import { GizmoWrapper } from "../../utils";
import { empty, full } from "../../../../assets/gizmos/rate-field/smileys";

type RateFieldProps = {
  gizmo: Gizmo;
};

const RateField: FC<RateFieldProps> = ({ gizmo }) => {
  const { features, binder, errors, config } = useGizmo({ gizmo });
  const [value, setValue] = useState<number>(0);
  const [buttonImages, setButtonImages] = useState<any[]>([]);

  useEffect(() => {
    if (binder) {
      const initValue = binder.getValue();
      if (initValue !== undefined) {
        setValue(initValue);
      }

      if (config.ops?.rateField?.type !== "stars") {
        setButtonImages(calculateButtonImages(initValue));
      }

      binder.value.subscribe((v: number) => {
        if (config.ops?.rateField?.type !== "stars") {
          setButtonImages(calculateButtonImages(v));
        }
        setValue(v);
      });
    }
  }, [binder]);

  /**
   * Calculate the images list to render
   *
   * @param {number} value value to calculate the images list
   * @returns
   */
  const calculateButtonImages = (value?: number) => {
    const buttonImages: any[] = [];

    if (config.ops?.rateField?.quantity) {
      let emptyImages = empty;
      let fullImages = full;

      if (config.ops.rateField.quantity === 3) {
        emptyImages = [empty[0], empty[2], empty[4]];
        fullImages = [full[0], full[2], full[4]];
      } else if (config.ops.rateField.quantity === 2) {
        emptyImages = [empty[0], empty[4]];
        fullImages = [full[0], full[4]];
      }

      for (let i = 0; i < config.ops.rateField.quantity; i++) {
        if (i === value) {
          buttonImages.push(fullImages[i]);
        } else {
          buttonImages.push(emptyImages[i]);
        }
      }
    }

    return buttonImages;
  };

  /**
   * Render the button image
   * @param {any} image image path to render
   * @param {number} index current image index
   * @returns
   */
  const renderButton = (image: any, index: number) => {
    if (features.editable) {
      return (
        <img
          key={`img-${index}`}
          src={image}
          style={{ width: "38px", padding: "5px", cursor: "pointer" }}
          onClick={() => handleRateChange(index + 1)}
        />
      );
    } else {
      return (
        <img
          key={`img-${index}`}
          src={image}
          style={{ width: "38px", padding: "5px" }}
        />
      );
    }
  };

  /**
   * Function that handles the rate change
   *
   * @param {number} v value to set
   */
  const handleRateChange = (v: number) => {
    if (v === 0 || v - 1 === value) {
      binder?.setValue(undefined);
    } else {
      binder?.setValue(v - 1);
    }
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      {config.ops?.rateField?.type === "stars" ? (
        <S.RateField
          aria-label={`input-${config.fid}`}
          onChange={handleRateChange}
          value={value !== undefined ? value + 1 : undefined}
          allowClear
          disabled={!features.editable}
          count={config.ops?.rateField?.quantity || 5}
        />
      ) : (
        buttonImages.map((image, index) => {
          return renderButton(image, index);
        })
      )}
    </GizmoWrapper>
  );
};

export default RateField;
