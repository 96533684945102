import styled from "styled-components";
import { SCREEN_SIZES } from "flowy-3-core";

const screenMaxWidths = {
  small: `(max-width: ${SCREEN_SIZES.small})`,
  medium: `(max-width: ${SCREEN_SIZES.medium})`,
  large: `(max-width: ${SCREEN_SIZES.large})`,
  extraLarge: `(min-width: ${SCREEN_SIZES.extraLarge})`,
};

const BaseProject = styled.div`
  @media ${screenMaxWidths.large} {
    padding: 0px 10%;
  }
  @media ${screenMaxWidths.medium} {
    padding: 0px 5%;
  }
  @media ${screenMaxWidths.small} {
    padding: 0px 2%;
  }
  @media ${screenMaxWidths.extraLarge} {
    padding: 0px 10%;
  }
`;
export { BaseProject };
