import { FC } from "react";
import { IError } from "flowy-3-core";
import { Button } from "antd";

type SearchResultAssociationsProps = {
  errors: IError[];
  gizmoSearchFeatures: any;
};

const SearchResultAssociations: FC<SearchResultAssociationsProps> = ({
  errors,
  gizmoSearchFeatures,
}) => {
  /**
   * Handle button click
   * @param {any} result search result
   */
  const handleButtonClick = (result: any) => {
    const association = result.associations[0];
    // if (association.parentFormId && association.parentFilledFormId) {
    //   const url = `/#/rg?rgfid=${association.parentFormId}&fid=${association.parentFilledFormId}&m=s`;
    //   window.open(url, "_blank");
    // }
    if (association.childFormId && association.childFilledFormId) {
      const url = `/#/rg?rgfid=${association.childFormId}&fid=${association.childFilledFormId}&m=s`;
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <br />
      <br />
      {gizmoSearchFeatures.results &&
        gizmoSearchFeatures.results.length === 1 &&
        gizmoSearchFeatures.results[0].associations &&
        gizmoSearchFeatures.results[0].associations.length > 0 &&
        errors.find((e) => e.code === "search.status") && (
          <Button
            onClick={() => handleButtonClick(gizmoSearchFeatures.results[0])}
            type="primary"
          >
            Abrir Entrega
          </Button>
        )}
    </>
  );
};

export default SearchResultAssociations;
