import { FC } from "react";
import { Gizmo, RootGizmo, useGizmo } from "flowy-3-core";
import * as S from "./Row.styles";
import renderGizmos from "../../render-gizmos";

type RowProps = {
  gizmo: Gizmo;
};

const Row: FC<RowProps> = ({ gizmo }) => {
  const { features, binder, config } = useGizmo({ gizmo });
  let gizmos: (Gizmo | RootGizmo)[] = [];

  if (binder) {
    gizmos = binder.getGizmos();
  }

  return (
    <S.Row id={`row-${gizmo.getConfig().fid}`}>
      {/* <S.Label>{features.label}</S.Label> */}
      <S.Children align="middle" justify={config.ops?.row?.justify || "start"}>
        {gizmos.length > 0 && renderGizmos(gizmos)}
      </S.Children>
    </S.Row>
  );
};

export default Row;
