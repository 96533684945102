import { FC, useState, useEffect } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./Indicator.styles";

type IndicatorProps = {
  gizmo: Gizmo;
};

const Indicator: FC<IndicatorProps> = ({ gizmo }) => {
  const { features } = useGizmo({ gizmo });
  const [value, setValue] = useState<number | undefined>();

  useEffect(() => {
    //! Use the binder instead of the gizmo
    const data: any = gizmo.getData();
    if (data && data["v"]) {
      setValue(data["v"]);
    }
  }, [gizmo]);

  return (
    <S.Indicator
      id={`indicator-${gizmo.getConfig().fid}`}
      columns={gizmo.getColumns()}
    >
      <label>{features.label}</label>
      {value && <div>{value}</div>}
    </S.Indicator>
  );
};

export default Indicator;
