import * as React from "react";
import { FlowyProject } from "../components/gizmos";
import usePlatformCB from "./usePlatformCB";
import { defaultProject } from "./gizmoConfigurations";
import * as S from "./DefaultProject.styles";

const DefaultProject: React.FC = () => {
  const { platformOperationCB, params } = usePlatformCB();

  return (
    <S.Container>
      <FlowyProject
        config={defaultProject}
        platformCallbacks={{
          platformOperationCB,
        }}
        params={params}
      />
    </S.Container>
  );
};

export default DefaultProject;
