import React, { createContext, useEffect, ReactNode, FC } from "react";
import { useFlowyGlobalContext, IFlowyNotification } from "flowy-3-core";
import { ToastContainer, toast } from "react-toastify";

type INotificationContext = {
  children: ReactNode[] | ReactNode;
};

export const FlowyNotificationsContext = createContext<any | undefined>(
  undefined
);

const FlowyNotificationsProvider: FC<INotificationContext> = ({ children }) => {
  const flowyGlobal = useFlowyGlobalContext();

  useEffect(() => {
    if (flowyGlobal) {
      flowyGlobal.notifications.subscribe(
        (notification: IFlowyNotification) => {
          if (notification.type === "success") {
            toast.success(notification.label);
          } else if (notification.type === "error") {
            toast.error(notification.label);
          }
        }
      );
    }
  }, [flowyGlobal]);

  return (
    <FlowyNotificationsContext.Provider value={undefined}>
      {children}
      <ToastContainer />
    </FlowyNotificationsContext.Provider>
  );
};

export default FlowyNotificationsProvider;
