import styled from "styled-components";
import { Upload } from "antd";
import RWebcam from "react-webcam";

const FrontImageFile = styled(Upload)`
  width: 100%;
`;

const BackImageFile = styled(Upload)`
  width: 100%;
`;

const Container = styled.div`
  border: 1px dotted #222;
  padding: 10px;
  border-radius: 15px;
`

const WebCam = styled(RWebcam)`
  max-width: 100%;
`;

const WebCamContainer = styled.div`
  text-align: center;
  background-color: "#eee";
  padding: 10px;
  border-radius: 15px;
`;

const CenterContainer = styled.div`
  text-align: center;
`;

export {
  FrontImageFile,
  BackImageFile,
  WebCam,
  Container,
  WebCamContainer,
  CenterContainer
};
