import { FC } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./Column.styles";

type ColumnProps = {
  gizmo: Gizmo;
};

const Column: FC<ColumnProps> = ({ gizmo }) => {
  const { features } = useGizmo({ gizmo });

  return (
    <S.Column id={`column-${gizmo.getConfig().fid}`}>
      <h1>Column: {features.label}</h1>
    </S.Column>
  );
};

export default Column;
