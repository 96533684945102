/**
 * Transform a file to a base64 string
 *
 * @param {File} file file to transform
 * @returns
 */
function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String.split(",")[1]); // extract base64 string from data URI
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export default fileToBase64;
