const downloadQRCode = (className: string, value: string) => {
  const qrCodeURL: any =
    document.getElementsByClassName(className)[0].children[0];
  if (qrCodeURL) {
    const imageData = qrCodeURL
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = imageData;
    aEl.download = value + ".png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }
};

export default downloadQRCode;
