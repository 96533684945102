/**
 * Transform a base64 string to a file
 *
 * @param {string} file base64 encoded file
 * @param {string} fileName name of the file
 * @param {string} type type of the file
 *
 * @returns
 */
const getFileFromBase64 = async (
  file: string,
  fileName: string,
  type: string
) => {
  const newF: any = await fetch(file);
  const blob = await newF.arrayBuffer();
  return new File([blob], fileName, { type });
};

export { getFileFromBase64 };
