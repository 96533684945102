import { FC, useState, useEffect } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./FileField.styles";
import { PreviewModal } from "../../utils";
import { Popconfirm } from "antd";
import type { RcFile } from "antd/es/upload/interface";
import transformFiles from "./transformFiles";
import GizmoWrapper from "../../utils/GizmoWrapper";

type FileFieldProps = {
  gizmo: Gizmo;
};

const FileField: FC<FileFieldProps> = ({ gizmo }) => {
  const { config, features, errors, binder } = useGizmo({ gizmo });
  const [fileList, setFileList] = useState<any>([]);
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewFile, setPreviewFile] = useState<{ type: string, file: any }>({ type: '', file: '' })

  useEffect(() => {
    if (binder) {
      const files = binder.getFiles();

      if (files.length > 0) {
        transformFiles(files).then((f) => setFileList(f));
      }

      binder.files.subscribe(async (files: File[]) => {
        setFileList(await transformFiles(files));
      });
    }
  }, [binder, gizmo]);

  const handleBeforeUpload = async (file: RcFile) => {
    await binder.setFiles([file]);
    return false;
  };

  const handleRemove = (file: any) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handlePreview = async (file: any) => {
    const fileToPreview = binder
      .getFiles()
      .find((f: File) => f.name === file.name);

    if (fileToPreview.type.includes("image")) {
      const url = URL.createObjectURL(fileToPreview);
      setPreviewFile({ type: fileToPreview.type, file: url })
    } else if (fileToPreview.type === 'application/pdf') {
      setPreviewFile({ type: fileToPreview.type, file: fileToPreview })
    }
    setPreviewOpen(true)
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      <PreviewModal
        open={previewOpen}
        setOpen={setPreviewOpen}
        previewFile={previewFile}
      />

      {fileList.length === 0 && !features.editable && (
        <><i>No se cargaron archivos</i><br /><br /></>
      )}

      {(fileList.length > 0 || features.editable) &&
        <S.FileField
          showUploadList={{
            showRemoveIcon: features.editable,
            removeIcon: (
              <Popconfirm
                title="Eliminar archivo!"
                description="Estás seguro de eliminar el archivo?"
                onConfirm={handleRemove}
                okText="Sí"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            )
          }}
          beforeUpload={handleBeforeUpload}
          fileList={fileList}
          maxCount={1}
          listType="picture-card"
          onPreview={handlePreview}
          disabled={!features.editable}
          accept={config.ops?.fileField?.accept || "*"}
        >
          {fileList.length < 1 && (
            <div>
              <PlusOutlined />
              <div>Cargar</div>
            </div>
          )}
        </S.FileField>
      }
    </GizmoWrapper>
  );
};

export default FileField;
