import { FC } from "react";
import { Gizmo, IGizmo } from "flowy-3-core";
import * as S from "./Custom.styles";

type CustomProps = {
  gizmo: Gizmo;
};

const Custom: FC<CustomProps> = ({ gizmo }) => {
  const config: IGizmo = gizmo.getConfig();
  let CustomGizmo: FC<CustomProps> | undefined;

  if (
    config.ops?.custom?.component &&
    typeof config.ops.custom.component === "function"
  ) {
    CustomGizmo = config.ops.custom.component;
  }

  return (
    <S.Custom
      id={`custom-${gizmo.getConfig().fid}`}
      columns={gizmo.getColumns()}
    >
      {/* <h1>Custom</h1> */}
      {CustomGizmo && <CustomGizmo gizmo={gizmo} />}
    </S.Custom>
  );
};

export default Custom;
