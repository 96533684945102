import React, { FC } from "react";
import { RootGizmo, useRootGizmo } from "flowy-3-core";
import * as S from "./View.styles";
import renderGizmos from "../../render-gizmos";

type ViewProps = {
  gizmo: RootGizmo;
};

const View: FC<ViewProps> = ({ gizmo }) => {
  const { features, screenGizmos } = useRootGizmo({ gizmo });
  return (
    <S.View>
      {features.displayLabel && <h1>{features.label}</h1>}
      {screenGizmos.length > 0 && renderGizmos(screenGizmos)}
    </S.View>
  );
};

export default View;
