import { useEffect, useState } from "react";
import { Gizmo, gnov } from "flowy-3-core";
import styled from "styled-components";
import { Button as AButton } from "antd";
import { toast } from "react-toastify";
import axios from "axios";

const Button = styled(AButton)`
  marign: 5px 0;
  width: 100%;
`;

type DownloadVoucherProps = {
  gizmo: Gizmo;
};

const DownloadVoucher = ({ gizmo }: DownloadVoucherProps) => {
  const [showButton, setShowButton] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    checkIfGizmoIsDisplayed(gizmo).then((showButton) => {
      setShowButton(showButton);
    });
  }, []);

  const handleButtonClick = async () => {
    const config = gizmo.getConfig();
    const flux = gizmo.getFlux();

    if (
      flux &&
      config.ops?.button?.download?.url &&
      config.ops.button.download.filename
    ) {
      setProcessing(true);
      const url = await flux.interpolate({
        script: config.ops.button.download.url,
      });

      try {
        const file = await downloadUrl(
          url,
          config.ops.button.download.filename
        );
        if (file) {
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(file);
          downloadLink.download = config.ops.button.download.filename;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } catch (e) {
        toast.error(e.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div>
      {/* <h1>Download Voucher</h1> */}
      {/* <Button type="primary">Descargar</Button> */}
      {showButton && (
        <Button type="primary" loading={processing} onClick={handleButtonClick}>
          Descargar Comprobante de Registro
        </Button>
      )}
    </div>
  );
};

function checkIfGizmoIsDisplayed(gizmo: Gizmo): Promise<boolean> {
  return new Promise<boolean>(async (resolve) => {
    const config = gizmo.getConfig();
    const flux = gizmo.getFlux();
    const user = gizmo.getDao()?.getUser();

    if (flux && config.ops?.button?.download?.url && user?.credentials) {
      // const url = await flux.interpolate({
      //   script: config.ops.button.download.url,
      // });
      const credentials: any = user.credentials;

      try {
        const dataUrl = gnov(
          gizmo.getConfig(),
          "ops.custom.customParams.dataUrl"
        );

        if (dataUrl) {
          const response = await axios({
            method: "POST",
            url: dataUrl,
            data: {
              fids: ["ok"],
            },
            headers: {
              ...credentials,
            },
          });

          if (response.status === 200) {
            const { data } = response;

            if (
              data?.registers &&
              Array.isArray(data.registers) &&
              data.registers.length > 1
            ) {
              return resolve(true);
            }
            if (data?.answers) {
              if (Object.keys(data.answers).length > 0) {
                return resolve(true);
              }
            }
          }
        }
      } catch (error) {
        console.log("-----------error: ", error);
      }
    }

    resolve(false);
  });
}

function downloadUrl(url: string, filename: string): Promise<File | undefined> {
  return new Promise<File | undefined>(async (resolve) => {
    try {
      const response = await axios({
        method: "GET",
        url,
        responseType: "blob",
        // TODO: credentials...
      });

      if (response.status === 200) {
        let type = "";
        if (filename?.includes("pdf")) {
          type = "application/pdf";
        } else if (filename?.includes("jpg")) {
          type = "image/jpeg";
        }

        const file = new File([response.data], filename || "downloaded_file", {
          type,
        });

        return resolve(file);
      }
    } catch (e) {
      throw new Error("Error al descargar el archivo");
    }

    resolve(undefined);
  });
}

export default DownloadVoucher;
