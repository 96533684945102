import { IGizmo } from "flowy-3-core";

const config: IGizmo = {
  fid: "login-form-fid",
  type: "form",
  label: "Login",
  ops: {
    form: {
      saveButtonLabel: "Ingresar",
      displayCancelButton: {
        isDisplayed: false,
      },
    },
    displayLabel: false,
  },
  gizmos: [
    {
      fid: "q0",
      type: "text_field",
      label: "Usuario",
      uid: "user",
    },
    {
      fid: "q1",
      type: "text_field",
      label: "Password",
      uid: "password",
      ops: {
        textField: {
          password: {
            isPassword: true,
          },
        },
      },
    },
  ],
};

export default config;
