import styled from "styled-components";
import { Row as RRow } from "antd";

const Row = styled.div`
  // border: 2px dashed purple;
`;

const Label = styled.div``;

// const Children = styled.div`
// `;
const Children = styled(RRow)``;

export { Row, Label, Children };
