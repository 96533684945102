import { Input } from "antd";
import styled from "styled-components";
import { GizmoContainerResize } from "../../utils";

const Index = styled(GizmoContainerResize)`
  // border: 2px dashed blue;
`;

const { Search: SearchAntd } = Input
const Search = styled(SearchAntd)`
  width: 250px;
`;

const Circle = styled.span`
  color: ${(props) => props.color};
  text-shadow: 0px 0px ${(props) => (props.color === "white" ? "2px" : "0px")}
    #000;
`;


export { Index, Search, Circle };
