import { FC, useRef, useEffect } from "react";
import { Input, Space, Button } from "antd";
import type { InputRef } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { IIndexColumn, Gizmo } from "flowy-3-core";

interface IColumnSearchProps {
  selectedKeys: string[];
  setSelectedKeys: Function;
  visible: boolean;
  confirm: Function;
  column: IIndexColumn;
  gizmo: Gizmo;
  onColumnSearch: Function;
}

const ColumnSearch: FC<IColumnSearchProps> = ({
  selectedKeys,
  setSelectedKeys,
  column,
  gizmo,
  onColumnSearch,
}) => {
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }, []);

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={searchInput}
        placeholder={`Buscar`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          onColumnSearch(selectedKeys, column);
        }}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
          onClick={() => {
            onColumnSearch(selectedKeys, column);
          }}
        >
          Buscar
        </Button>
      </Space>
    </div>
  );
};

export default ColumnSearch;
