import { DownloadOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Image, Modal, Spin } from "antd";
import { FC, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

type PreviewModalProps = {
  open: boolean
  setOpen: (value: boolean) => void
  previewFile: { type: string, file: any }
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PreviewModal: FC<PreviewModalProps> = ({ open, setOpen, previewFile }) => {

  const [numPages, setNumPages] = useState<number>();
  const [scale, setScale] = useState(1);

  const handleDownload = () => {
    const url = URL.createObjectURL(previewFile.file);
    const link = document.createElement('a');
    link.href = url;
    link.download = previewFile.file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };


  return (
    <Modal
      open={open}
      footer={null}
      onCancel={() => setOpen(false)}
      style={{ top: 20 }}
      width={800}
    >
      {previewFile.type?.includes('image') && (
        <Image
          src={previewFile.file}
          preview={false}
        />
      )}
      {previewFile.type === 'application/pdf' && (
        <div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
            <Button onClick={handleZoomOut}><MinusOutlined /></Button>
            <Divider type="vertical" />
            <Button onClick={handleZoomIn}><PlusOutlined /></Button>
            <Divider type="vertical" />
            <Button onClick={() => handleDownload()}>Descargar<DownloadOutlined /></Button>
          </div>
          <div style={{ overflowX: "scroll", textAlign: 'center' }}>
            <Document
              file={previewFile.file}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              loading={<Spin />}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  scale={scale}
                />
              ))}
            </Document>
          </div>
        </div>
      )}
    </Modal>
  )
};

export default PreviewModal;
