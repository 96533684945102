import { DownloadOutlined } from "@ant-design/icons";
import { Space, Typography, QRCode, Button } from "antd";
import { useSearchParams } from "react-router-dom";
import { downloadQRCode } from "../../gizmos/utils/functions";

const { Title } = Typography;
const Folio = () => {
  const [searchParams] = useSearchParams();

  const value = searchParams.get("folio");

  return (
    <div style={{ textAlign: "center" }}>
      {value && (
        <Space direction="vertical" align="center">
          <Title level={5}>{value}</Title>
          <QRCode size={200} value={value} className="folio-image" />
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={() => downloadQRCode("folio-image", value)}
          />
        </Space>
      )}
    </div>
  );
};

export default Folio;
