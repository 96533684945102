import { Modal, Table as TableAntd } from "antd";
import styled from "styled-components";

const AnnexForm = styled.div`
  width: 100%;
`;

const AnnexFormModalContainer = styled(Modal)`
  width: 80%;
`;

const Table = styled(TableAntd)`
  width: 100%;
`;

export {
  AnnexForm,
  AnnexFormModalContainer,
  Table
};
