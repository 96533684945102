import { ReactNode } from "react";
import { IGizmoFeatures } from "flowy-3-core";

type GizmoDisplayProps = {
  features: IGizmoFeatures;
  children: ReactNode[] | ReactNode;
};

/**
 * Render or not a gizmo based on the display feature
 *
 * @param {IGizmoFeatures} features gizmo features coming from the core
 * @param {ReactNode[] | ReactNode} children children to be rendered
 * @returns
 */
const GizmoDisplay = ({ features, children }: GizmoDisplayProps) => {
  return <>{features.display === true && children}</>;
};

export default GizmoDisplay;
