import { Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";

/**
 * Routes nested in here are going to be redirected to login if user is not authenticated
 */
const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth();

  // We had to check for isAuthenticated to be undefined first because the custom hook by default
  // returns an undefined. It later is assigned a boolean value.
  return isAuthenticated === undefined ? null : isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

/**
 * It's intended to be used only on login, whenever users are already login don't show the login.
 * Just redirect them to the root route
 */
const RedirectToRoot = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated === undefined ? null : !isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export { PrivateRoutes, RedirectToRoot };
