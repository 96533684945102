import { FC, useRef, useEffect, useState } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./SignatureField.styles";
import { GizmoWrapper } from "../../utils";
import { Alert, Button, Divider, Image as ImgAntd, Modal } from "antd";
import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import { getFileFromBase64 } from "../../utils/functions/getFile";
import SignaturePad from "signature_pad";
import { fileToBase64 } from "../../utils/functions";

type SignatureFieldProps = { gizmo: Gizmo };

const SignatureField: FC<SignatureFieldProps> = ({ gizmo }) => {
  const isMobile = /Mobi/.test(window.navigator.userAgent);
  const { features, errors, binder } = useGizmo({ gizmo });
  const [value, setValue] = useState<any>("");
  const sigCanvas = useRef<any>(null);
  const [signaturePad, setSignaturePad] = useState<any>(null);
  const [showSizeWarning, setShowSizeWarning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [img, setImg] = useState<any>();

  useEffect(() => {
    if (binder) {
      const loadSignature = () => {
        const files = binder.getFiles();
        let canvas: any;
        if (
          features.editable &&
          features.display &&
          (!isMobile || (isMobile && isModalOpen))
        ) {
          canvas = sigCanvas.current;
          const signaturePad = new SignaturePad(canvas);
          setSignaturePad(signaturePad);
          canvas.width = canvas.offsetWidth;
          canvas.height = canvas.offsetHeight;
          setShowSizeWarning(window.innerWidth < 500);
        }

        if (files.length) {
          const file = files[0];
          fileToBase64(file).then((value) => {
            setImg("data:image/png;base64," + value);
          });
          if (features.editable && (!isMobile || (isMobile && isModalOpen))) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const img: any = new Image();
              img.onload = () => {
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, img.width, img.height);
              };
              img.src = event?.target?.result;
            };
            reader.readAsDataURL(file);
          } else {
            fileToBase64(file).then((value) => {
              setValue("data:image/png;base64," + value);
            });
          }
        }
      };

      // Files are loaded asynchronously, so we need to subscribe to the files observable
      binder.files.subscribe((files: File[]) => {
        loadSignature();
      });
      loadSignature();

      window.addEventListener("resize", loadSignature);
      return () => {
        window.removeEventListener("resize", loadSignature);
      };
    }
  }, [binder, features.display, isModalOpen]);

  useEffect(() => {
    return () => {
      if (signaturePad) signaturePad.off();
    };
  }, [signaturePad]);

  const handleClearSignature = async () => {
    await binder?.setFiles([]);
    setImg(undefined);
    if (signaturePad) signaturePad.clear();
  };

  const handleSignatureDraw = async () => {
    if (signaturePad && features.editable) {
      const newSignature = signaturePad.toDataURL("image/png");
      setImg(newSignature);
      const file = await getFileFromBase64(
        newSignature,
        "signature.png",
        "image/png"
      );
      await binder?.setFiles([file]);
    }
  };

  const showModal = () => {
    handleSignatureDraw();
    setIsModalOpen(true);
  };

  const saveSignature = () => {
    handleSignatureDraw();
    setIsModalOpen(false);
  };

  const hideSignatureModal = () => {
    setIsModalOpen(false);
  };

  const SignatureComponent = () => (
    <>
      {showSizeWarning && (
        <Alert
          message="Gire la pantalla para editar la firma con más comodidad!"
          type="warning"
          showIcon
          closable
        />
      )}
      <S.ContainerSignature>
        <S.SignatureField ref={sigCanvas} onClick={handleSignatureDraw} />
      </S.ContainerSignature>
      <Button onClick={handleClearSignature} icon={<ClearOutlined />}>
        Limpiar
      </Button>
    </>
  );

  return (
    <GizmoWrapper features={features} errors={errors}>
      {!features.editable ? (
        value ? (
          <S.ContainerImage>
            <ImgAntd src={value} preview={false} />
          </S.ContainerImage>
        ) : (
          <>
            <i>No se firmó</i>
            <br />
            <br />
          </>
        )
      ) : isMobile ? (
        <>
          {img?.length > 40 && (
            <S.ContainerImage>
              <ImgAntd src={img} preview={false} />
            </S.ContainerImage>
          )}
          <Button type="primary" onClick={showModal}>
            {img?.length > 40 ? "Editar" : "Agregar"} firma
          </Button>
          {img?.length > 40 && (
            <>
              <Divider type="vertical" />
              <Button type="primary" onClick={handleClearSignature}>
                Eliminar firma
              </Button>
            </>
          )}
          <Modal
            title="Firma"
            open={isModalOpen}
            footer={null}
            onCancel={hideSignatureModal}
          >
            {SignatureComponent()}
            <Button onClick={saveSignature} icon={<SaveOutlined />}>
              Guardar
            </Button>
          </Modal>
        </>
      ) : (
        SignatureComponent()
      )}
    </GizmoWrapper>
  );
};

export default SignatureField;
