import { Gizmo } from "flowy-3-core";
import styled from "styled-components";
import { Col, Divider, Typography, Row } from "antd";

const FooterContainer = styled(Row)`
  padding: 0px 5%;
`;
const { Paragraph } = Typography;

type InfoProps = {
  gizmo: Gizmo;
};

const Info = ({ gizmo }: InfoProps) => {
  return (
    <div style={{ paddingTop: "200px" }}>
      <Divider />
      <FooterContainer gutter={10}>
        <Col span={24}>
          <Typography>
            <Paragraph>
              Si no cuentas con documentos para el registro, por favor dirígete
              a la Administración de FARO en horario de 9 a.m. a 4 p.m. de lunes
              a viernes en la dirección: Supermanzana 259, Manzana 83, lote 06,
              Av. Leona Vicario, Colonia Villas Otoch Paraíso, Cancún.
            </Paragraph>
          </Typography>
        </Col>
      </FooterContainer>
    </div>
  );
};

export default Info;
